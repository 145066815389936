import { AxiosRequestConfig } from 'axios';
import { Book, BookStatusType } from 'src/redux/models';
import { HttpClient } from './HttpClient';

export type Response = {
	message: string;
	statusCode: number;
};
export interface BookStatusUpdateRequest {
	bookId: string;
	status: BookStatusType;
}

export interface BooksStatusUpdateRequest {
	ids: string[];
	status: string;
}

export interface TaskRequest {
	status: string;
	ids: string[];
}

export class ControlApiManager extends HttpClient {
	private static _instance: ControlApiManager;

	public static get client() {
		if (!this._instance) {
			this._instance = new ControlApiManager();
		}

		return this._instance;
	}

	constructor(config?: AxiosRequestConfig) {
		super({
			baseURL: process.env.REACT_APP_API_SERVER_URL,
			headers: {
				'content-type': 'application/json',
			},
			timeout: 600000,
			...config,
		});
	}

	public async proceedNextTask(data: TaskRequest) {
		return this._instance.put<any, Response>('books', { data });
	}

	public async updateBookStatus(data: BookStatusUpdateRequest) {
		return this._instance.put<any, Response>(`books/${data.bookId}/status`, {
			data: {
				status: data.status,
			},
		});
	}

	public async updateBooksStatus(data: BooksStatusUpdateRequest) {
		return this._instance.put<any, Response>('books/status', { data });
	}

	public async yieldProduct(
		book: Book,
		productType: 'content_preview' | 'indd' | 'pdf',
	) {
		return this._instance.post<any, Response>(
			`books/${book.id}/preview/${productType}`,
			{
				data: {
					book,
				},
			},
		);
	}

	public async assignTask(data: { responsibility: string; ids: string[] }) {
		return this._instance.put<any, Response>('books/task', { data });
	}

	public async copyBook(data: { bookId: string }) {
		return this._instance.post<any, Response>('books/copy', {
			data: {
				bookId: data.bookId,
				status: 'print_pending',
				copy: 1,
			},
		});
	}

	public async cancelBook(bookId: string) {
		return this._instance.post<any, Response>(`books/${bookId}/cancel`);
	}

	public async cancelOrder(id: string) {
		return this._instance.post<any, Response>(`orders/${id}/cancel`);
	}
}

export const ControlApi = ControlApiManager.client;
