import Axios, {
	AxiosError,
	AxiosResponse,
	AxiosInstance,
	AxiosRequestConfig,
} from 'axios';
import { plainToClass } from 'class-transformer';
import { validate } from 'class-validator';

export interface ClassConstructor<T = any> extends Function {
	new (...args: any[]): T;
}

export class SkipError extends Error {
	public isSkip: boolean = true;
	public request: AxiosRequestConfig;

	constructor(message: string, config: AxiosRequestConfig) {
		super(message);
		this.request = config;
	}
}

type RequestPending = {
	[key: string]: boolean;
};

export abstract class HttpClient {
	protected readonly _instance: AxiosInstance;
	private requestPending: RequestPending = {};

	constructor(config: AxiosRequestConfig) {
		this._instance = Axios.create(config);
		this._handleResponse = this._handleResponse.bind(this);
		this._handleErrorResponse = this._handleErrorResponse.bind(this);
		this._initializeRequestInterceptor();
		this._initializeResponseInterceptor();
	}

	private _initializeRequestInterceptor() {
		this._instance.interceptors.request.use(this._interceptOutgoingRequest);
	}

	private _initializeResponseInterceptor() {
		this._instance.interceptors.response.use(
			this._handleResponse,
			this._handleErrorResponse,
		);
	}

	private getUniqueUrl(config: AxiosRequestConfig): string {
		return `${config.url}&${config.method}`;
	}

	private isPending(config: AxiosRequestConfig): boolean {
		const uniqueUrl = this.getUniqueUrl(config);

		if (!this.requestPending[uniqueUrl]) {
			this.requestPending[uniqueUrl] = false;
		}

		return this.requestPending[uniqueUrl];
	}

	private setPending(config: AxiosRequestConfig, state: boolean): void {
		const uniqueUrl = this.getUniqueUrl(config);
		this.requestPending[uniqueUrl] = state;
	}

	private _interceptOutgoingRequest = (
		config: AxiosRequestConfig,
	): AxiosRequestConfig => {
		if (this.isPending(config)) {
			const skipError = new SkipError('Skip Request', config);
			throw skipError;
		}

		this.setPending(config, true);

		const token =
			config.headers.Authorization || localStorage.getItem('accessToken');

		return {
			...config,
			headers: {
				...config.headers,
				Authorization: `Bearer ${token}` ?? '',
			},
		};
	};

	private _handleResponse(response: AxiosResponse) {
		this.setPending(response.config, false);
		return response.data;
	}

	private _handleErrorResponse(error: AxiosError) {
		if (error.isAxiosError) {
			this.setPending(error.response?.config || error.config, false);
		} else {
			this.setPending(error.request, false);
		}

		console.group('NETWORK ERROR: ', error.message);
		console.dir(error);
		console.groupEnd();

		return Promise.reject(error);
	}

	protected async validateInput<T>(metatype: ClassConstructor<any>, value: T) {
		const data = plainToClass(metatype, value);
		const errors = await validate(data);

		if (errors.length > 0) {
			console.log('VALIDATION ERROR: ', errors);
			throw new Error('Validation failed');
		}

		return value;
	}

	protected getQueryString(query: { [key: string]: string }): string {
		let querystring: string = '';

		for (const [key, value] of Object.entries(query)) {
			querystring += `${key}=${value}`;
		}

		return querystring;
	}
}
