import { BookTypes } from 'src/redux/models';
import { AdminApi } from './AdminApi';

interface Product {
	_id: string;
	book_type: BookTypes;
	preview_page_index: number[];
	preview_spread_index: number[];
}

export class ProductApi extends AdminApi {
	private static _productClient: ProductApi;

	public static get client() {
		if (!this._productClient) {
			this._productClient = new ProductApi({ domain: 'products' });
		}

		return this._productClient;
	}

	public async getProductByType(type: BookTypes) {
		return this._instance.get<any, Product>(type);
	}
}
