import { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

interface GuestGuardProps {
	children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
	const { isAuthenticated } = useAuth() as any;

	if (isAuthenticated) {
		return <Redirect to="/app/controls/dashboard" />;
	}

	return <>{children}</>;
};

GuestGuard.propTypes = {
	children: PropTypes.node,
};

export default GuestGuard;
