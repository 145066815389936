import { useState, useRef, FC } from 'react';
import { capitalCase } from 'change-case';
import {
	Badge,
	Box,
	Button,
	FormControlLabel,
	IconButton,
	Popover,
	SvgIcon,
	Switch,
	TextField,
	Tooltip,
	Typography,
	makeStyles,
} from '@material-ui/core';
import { Settings as SettingsIcon } from 'react-feather';
import useSettings from 'src/hooks/useSettings';
import { Theme } from 'src/theme';
import { THEMES } from 'src/constants';

const useStyles = makeStyles((theme: Theme) => ({
	badge: {
		height: 10,
		width: 10,
		borderRadius: 5,
		marginTop: 10,
		marginRight: 5,
	},
	popover: {
		width: 320,
		padding: theme.spacing(2),
	},
}));

const Settings: FC = () => {
	const classes = useStyles();
	const ref = useRef<any>(null);
	const { settings, saveSettings } = useSettings();
	const [isOpen, setOpen] = useState<boolean>(false);
	const [values, setValues] = useState({
		direction: settings.direction,
		responsiveFontSizes: settings.responsiveFontSizes,
		theme: settings.theme,
	});

	const handleOpen = (): void => {
		setOpen(true);
	};

	const handleClose = (): void => {
		setOpen(false);
	};

	const handleChange = (field, value): void => {
		setValues({
			...values,
			[field]: value,
		});
	};

	const handleSave = (): void => {
		saveSettings(values);
		setOpen(false);
	};

	return (
		<>
			<Tooltip title="Settings">
				<Badge
					color="secondary"
					variant="dot"
					classes={{ badge: classes.badge }}
				>
					<IconButton color="inherit" onClick={handleOpen} ref={ref}>
						<SvgIcon fontSize="small">
							<SettingsIcon />
						</SvgIcon>
					</IconButton>
				</Badge>
			</Tooltip>
			<Popover
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				classes={{ paper: classes.popover }}
				anchorEl={ref.current}
				onClose={handleClose}
				open={isOpen}
			>
				<Typography variant="h4" color="textPrimary">
					Settings
				</Typography>
				<Box mt={2} px={1}>
					<FormControlLabel
						control={
							<Switch
								checked={values.direction === 'rtl'}
								edge="start"
								name="direction"
								onChange={(event) =>
									handleChange(
										'direction',
										event.target.checked ? 'rtl' : 'ltr',
									)
								}
							/>
						}
						label="RTL"
					/>
				</Box>
				<Box mt={2} px={1}>
					<FormControlLabel
						control={
							<Switch
								checked={values.responsiveFontSizes}
								edge="start"
								name="direction"
								onChange={(event) =>
									handleChange('responsiveFontSizes', event.target.checked)
								}
							/>
						}
						label="Responsive font sizes"
					/>
				</Box>
				<Box mt={2}>
					<TextField
						fullWidth
						label="Theme"
						name="theme"
						onChange={(event) => handleChange('theme', event.target.value)}
						select
						SelectProps={{ native: true }}
						value={values.theme}
						variant="outlined"
					>
						{Object.keys(THEMES).map((theme) => (
							<option key={theme} value={theme}>
								{capitalCase(theme)}
							</option>
						))}
					</TextField>
				</Box>
				<Box mt={2}>
					<Button
						variant="contained"
						color="secondary"
						fullWidth
						onClick={handleSave}
					>
						Save Settings
					</Button>
				</Box>
			</Popover>
		</>
	);
};

export default Settings;
