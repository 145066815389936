import { FC, ReactNode } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, ListItem, makeStyles } from '@material-ui/core';

interface NavItemProps {
	children?: ReactNode;
	className?: string;
	depth: number;
	href?: string;
	title: string;
}

const useStyles = makeStyles((theme) => ({
	item: {
		display: 'block',
		paddingTop: 0,
		paddingBottom: 0,
	},
	itemLeaf: {
		display: 'flex',
		paddingTop: 0,
		paddingBottom: 0,
	},
	button: {
		padding: '10px 8px',
		justifyContent: 'flex-start',
		textTransform: 'none',
		letterSpacing: 0,
		width: '100%',
	},
	buttonLeaf: {
		padding: '10px 8px',
		justifyContent: 'flex-start',
		textTransform: 'none',
		letterSpacing: 0,
		width: '100%',
		fontWeight: theme.typography.fontWeightRegular,
		'&.depth-0': {
			fontWeight: theme.typography.fontWeightMedium,
		},
	},
	active: {
		color: theme.palette.secondary.main,
		fontWeight: theme.typography.fontWeightMedium,
	},
}));

const NavItem: FC<NavItemProps> = ({
	children,
	className,
	depth,
	href,
	title,
	...rest
}) => {
	const classes = useStyles();
	let paddingLeft = 8;

	if (depth > 0) {
		paddingLeft = 32 + 8 * depth;
	}

	const style = { paddingLeft };

	if (children) {
		return (
			<ListItem
				className={clsx(classes.item, className)}
				disableGutters
				{...rest}
			>
				<Button className={classes.button} style={style}>
					{title}
				</Button>
				{children}
			</ListItem>
		);
	}

	return (
		<ListItem
			className={clsx(classes.itemLeaf, className)}
			disableGutters
			{...rest}
		>
			<Button
				activeClassName={classes.active}
				className={clsx(classes.buttonLeaf, `depth-${depth}`)}
				component={RouterLink}
				exact
				style={style}
				to={href}
			>
				{title}
			</Button>
		</ListItem>
	);
};

NavItem.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	depth: PropTypes.number.isRequired,
	href: PropTypes.string,
	title: PropTypes.string.isRequired,
};

export default NavItem;
