import {
	IsEmail,
	IsEnum,
	IsIn,
	IsNotEmpty,
	IsString,
	Matches,
	MaxLength,
	MinLength,
} from 'class-validator';
import { UserRoleType } from '@types';
import { UserRole } from 'src/constants/enum';

export class SignupDto {
	@IsEmail()
	@IsNotEmpty()
	email: string;

	@IsString()
	@IsNotEmpty()
	@MinLength(8)
	@MaxLength(20)
	@Matches(/((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/, {
		message: 'password too weak',
	})
	password: string;

	@IsEnum(UserRole)
	role: UserRoleType;
}
