import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PartialDeep } from 'type-fest';
import _ from 'lodash';

import objFromArray from 'src/utils/objFromArray';
import { FirebaseService } from 'src/config';
import { AppThunk } from 'src/redux/stores';
import { Book } from '../models';

interface BookState {
	books: {
		byId: Record<string, Book>;
		allIds: string[];
	};
}

export interface PartialBook extends PartialDeep<Book> {
	id: string;
}

const initialState: BookState = {
	books: {
		byId: {},
		allIds: [],
	},
};

const slice = createSlice({
	name: 'book',
	initialState,
	reducers: {
		getBooks(state: BookState, action: PayloadAction<{ books: Book[] }>) {
			const { books } = action.payload;

			state.books.byId = objFromArray(books);
			state.books.allIds = Object.keys(state.books.byId);
		},
		getBook(state: BookState, action: PayloadAction<{ book: Book }>) {
			const { book } = action.payload;

			state.books.byId[book.id] = book;

			if (!state.books.allIds.includes(book.id)) {
				state.books.allIds.push(book.id);
			}
		},
		addBooks(state: BookState, action: PayloadAction<{ books: Book[] }>) {
			const { books } = action.payload;
			_.forEach(books, (book) => (state.books.byId[book.id] = book));

			const ids = _.map(books, ({ id }) => id);
			state.books.allIds = _.uniq(state.books.allIds.concat(ids));
		},
		addBook(state: BookState, action: PayloadAction<{ book: Book }>) {
			const { book } = action.payload;

			state.books.byId[book.id] = book;
			state.books.allIds = _.uniq(state.books.allIds.concat(book.id));
		},
		updateBook(state: BookState, action: PayloadAction<{ book: PartialBook }>) {
			const { book } = action.payload;
			const prevBook = state.books.byId[book.id];
			state.books.byId[book.id] = _.merge(prevBook, book);
		},
	},
});

export const reducer = slice.reducer;

export const addBooks = (books: Book[]): AppThunk => (dispatch) => {
	dispatch(slice.actions.addBooks({ books }));
};

export const addBook = (book: Book) => (dispatch) => {
	dispatch(slice.actions.addBook({ book }));
};

export const getBook = (id: string) => async (dispatch) => {
	const book = await FirebaseService.getBookById(id);
	dispatch(slice.actions.addBook({ book }));
};

export const updateBook = (book: PartialBook): AppThunk => async (dispatch) => {
	dispatch(slice.actions.updateBook({ book }));
};
