import { FirebaseService, DIRS_PATH } from 'src/config';
import { SYNOLOGY_URL, UPLOADER_URL } from 'src/constants';
import { HttpClient } from './HttpClient';

type StoreData = {
	indd: string;
	indd_reviewed: string;
	background_removal: string;
	psd: string;
};

export type DirType = keyof StoreData;

type Store = Map<DirType, string>;

export class NasApiManager extends HttpClient {
	private readonly host: string = `${SYNOLOGY_URL}/webapi/entry.cgi`;
	private readonly path: Store = new Map();

	constructor() {
		super({
			baseURL: UPLOADER_URL,
			timeout: 600000,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});

		this._init(DIRS_PATH);
	}

	private _init(data: StoreData) {
		for (const [key, value] of Object.entries(data)) {
			this.path.set(key as DirType, value);
		}
	}

	private _getPathBy(key: DirType): string {
		return this.path.get(key);
	}

	private join(...args): string {
		return args.join('/');
	}

	public getDirPathBy(type: DirType, id: string) {
		return this.join(this._getPathBy(type), id);
	}

	public async getSid(): Promise<string> {
		const sid = FirebaseService.getNasToken();

		if (!sid) {
			throw new Error('Invalid sid of NAS');
		}

		return sid;
	}

	private async getDownloadLink(type: DirType, id: string): Promise<string> {
		const sid = await this.getSid();
		const dir = this.getDirPathBy(type, id);
		const path = encodeURIComponent(dir);
		return `${this.host}?api=SYNO.FileStation.Download&version=2&method=download&mode=download&path=${path}&_sid=${sid}`;
	}

	public getAccessLink(type: DirType, id: string) {
		const dir = this.getDirPathBy(type, id);
		const path = encodeURIComponent(dir);
		return `${SYNOLOGY_URL}/index.cgi?launchApp=SYNO.SDS.App.FileStation3.Instance&launchParam=openfile%3D${path}`;
	}

	public async downloadDirBy(type: DirType, id: string): Promise<void> {
		const url = await this.getDownloadLink(type, id);
		window.open(url, '_self');
	}

	public getAccessToDir(type: DirType, id: string): void {
		const url = this.getAccessLink(type, id);
		window.open(url, '_blank');
	}

	public uploadFile(formData: FormData) {
		return this._instance.post('upload', formData);
	}

	public async downloadMultipleDirsBy(type: DirType, ids: string[]) {
		const sid = await this.getSid();
		const paths = ids.map((id): string => this.getDirPathBy(type, id));
		const url = `${
			this.host
		}?api=SYNO.FileStation.Download&version=2&method=download&mode=download&_sid=${sid}&path=${encodeURIComponent(
			JSON.stringify(paths),
		)}`;

		window.open(url, '_self');
	}
}

export const NasApi = new NasApiManager();
