import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
	AppBar,
	Box,
	Hidden,
	IconButton,
	Toolbar,
	makeStyles,
	SvgIcon,
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import { Theme } from 'src/theme/createTheme';
import Account from './Account';
import Contacts from './Contacts';
import Notifications from './Notifications';
import Search from './Search';
import Settings from './Settings';

interface TopBarProps {
	className?: string;
	onMobileNavOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		zIndex: theme.zIndex.drawer + 100,
		...(theme.name === THEMES.LIGHT
			? {
					boxShadow: 'none',
					backgroundColor: theme.palette.primary.main,
			  }
			: {}),
		...(theme.name === THEMES.ONE_DARK
			? {
					backgroundColor: theme.palette.background.default,
			  }
			: {}),
	},
	toolbar: {
		minHeight: 64,
	},
}));

const TopBar: FC<TopBarProps> = ({ className, onMobileNavOpen, ...rest }) => {
	const classes = useStyles();

	return (
		<AppBar className={clsx(classes.root, className)} {...rest}>
			<Toolbar className={classes.toolbar}>
				<Hidden lgUp>
					<IconButton color="inherit" onClick={onMobileNavOpen}>
						<SvgIcon fontSize="small">
							<MenuIcon />
						</SvgIcon>
					</IconButton>
				</Hidden>
				<Hidden mdDown>
					<RouterLink to="/">
						<Logo backgroundColor="white" backgroundShape="circle" />
					</RouterLink>
				</Hidden>
				<Box ml={2} flexGrow={1} />
				{/* <Search />
				<Contacts />
				<Notifications /> */}
				<Settings />
				<Box ml={2}>
					<Account />
				</Box>
			</Toolbar>
		</AppBar>
	);
};

TopBar.propTypes = {
	className: PropTypes.string,
	onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
	onMobileNavOpen: () => {},
};

export default TopBar;
