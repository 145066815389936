import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/redux/slices/calendar';
import { reducer as chatReducer } from 'src/redux/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/redux/slices/kanban';
import { reducer as mailReducer } from 'src/redux/slices/mail';
import { reducer as notificationReducer } from 'src/redux/slices/notification';
import { reducer as bookReducer } from 'src/redux/slices/book';
import { reducer as orderReducer } from 'src/redux/slices/order';

const rootReducer = combineReducers({
	book: bookReducer,
	order: orderReducer,
	calendar: calendarReducer,
	chat: chatReducer,
	form: formReducer,
	kanban: kanbanReducer,
	mail: mailReducer,
	notifications: notificationReducer,
});

export default rootReducer;
