import { UserRoleType } from '@types';
import { SigninDto } from 'src/dto/signin.dto';
import { AdminApi } from './AdminApi';

export interface UserPayload {
	email: string;
	password: string;
	role: UserRoleType;
}

export class AuthApi extends AdminApi {
	private static _authClient: AuthApi;

	public static get client() {
		if (!this._authClient) {
			this._authClient = new AuthApi({
				domain: 'auth',
			});
		}

		return this._authClient;
	}

	public async login(signinDto: SigninDto): Promise<string> {
		await this.validateInput(SigninDto, signinDto);

		return this._instance
			.post<any, { access_token: string }>('login', signinDto)
			.then((data) => data.access_token);
	}

	public async logout() {
		return this._instance.post('logout');
	}
}
