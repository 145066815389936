import { AxiosRequestConfig } from 'axios';
import { BookStatusType } from 'src/redux/models';
import { HttpClient } from './HttpClient';

interface NotificationRequest {
	bookId: string;
	status: BookStatusType;
}

export class NotiApiManager extends HttpClient {
	private static instance: NotiApiManager;

	public static get client() {
		if (!this.instance) {
			this.instance = new NotiApiManager();
		}

		return this.instance;
	}

	constructor(config?: AxiosRequestConfig) {
		super({
			baseURL: process.env.REACT_APP_NOTI_SERVER_URL,
			headers: {
				'content-type': 'application/json',
			},
			timeout: 60000,
			...config,
		});
	}

	public async sendRevisionMsg(bookId: string) {
		return this._instance.post<any, Response>('notification/revision', {
			data: { bookId },
		});
	}

	public async sendMessage(data: NotificationRequest) {
		return this._instance.post('notification', {
			data: {
				type: 'kakao',
				...data,
			},
		});
	}
}

export const NotiApi = NotiApiManager.client;
