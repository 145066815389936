import { Book, Order } from 'src/redux/models';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { firebaseConfig } from './credential';
import { PartialDeep } from 'type-fest';

if (!firebase.apps.length) {
	firebase.initializeApp(firebaseConfig);
}

type DocumentData = firebase.firestore.DocumentData;
type FirestoreInstance = firebase.firestore.Firestore;

type QuerySnapshot<T = DocumentData> = firebase.firestore.QuerySnapshot<T>;
type DocumentSnapshot<T> = firebase.firestore.DocumentSnapshot<T>;
type CollectionReference<
	T = DocumentData
> = firebase.firestore.CollectionReference<T>;
type Query<T> = firebase.firestore.Query<T>;
type FirestoreError = firebase.firestore.FirestoreError;

type CollectionGroup<T = DocumentData> = (path: string) => Query<T>;
type Collection<T = DocumentData> = (path: string) => CollectionReference<T>;
type DocumentReference<T> = firebase.firestore.DocumentReference<T>;

type WhereFilterOp = firebase.firestore.WhereFilterOp;

interface PartialBook extends PartialDeep<Book> {
	order_id: string;
}

export const db = firebase.firestore();

// if (location.hostname === 'localhost') {
// 	db.useEmulator('localhost', 8080);
// }

export class FirebaseService {
	//* Private methods
	private static readonly _database: FirestoreInstance = firebase.firestore();

	public static get database() {
		return this._database;
	}

	public static get batch() {
		return this._database.batch();
	}

	private static collection<T>(path: string) {
		return (this._database.collection as Collection<T>)(path);
	}

	private static collectionGroup<T>(path: string) {
		return (this._database.collectionGroup as CollectionGroup<T>)(path);
	}

	//* Helper
	public static parseDocuments<T>(collection: QuerySnapshot<T>): T[] {
		return collection.docs.map((docs) => docs.data());
	}

	public static get bookQuery() {
		return this.addQueryCondition(this.collectionGroup<Book>('books'));
	}

	public static get orderQuery() {
		return this.addQueryCondition(this.collectionGroup<Order>('orders'));
	}

	public static addQueryCondition<T>(query: Query<T> | CollectionReference<T>) {
		return query.where('paid', '==', true);
	}

	public static buildQuery<T>(collection: 'books' | 'orders') {
		return this.collectionGroup<T>(collection);
	}

	//* Public methods
	public static getCollectionData<T>(name: string) {
		return this.collection<T>(name)
			.get()
			.then(this.parseDocuments);
	}

	public static getCollectionGroupData<T>(name: string) {
		return this.collectionGroup<T>(name)
			.get()
			.then(this.parseDocuments);
	}

	public static getBookById(id: string) {
		return this.collection<Book>('books')
			.where('id', '==', id)
			.get()
			.then(this.parseDocuments)
			.then(([book]) => book);
	}

	public static getOrderById(orderId: string) {
		return this.collection<Order>('orders')
			.where('orderId', '==', orderId)
			.get()
			.then(this.parseDocuments)
			.then(([book]) => book);
	}

	public static getBooks(condition?: [keyof Book, WhereFilterOp, any]) {
		let query = this.bookQuery;

		if (condition) {
			query = query.where(...condition);
		}

		return query.get().then(this.parseDocuments);
	}

	public static getOrders(condition?: [string, WhereFilterOp, any]) {
		let query = this.orderQuery;

		if (condition) {
			query = query.where(...condition);
		}

		return query.get().then(this.parseDocuments);
	}

	public static onSnapshotCollection<T>({
		orderBy = [],
		where = [],
		path,
		observer,
	}: {
		orderBy?: [string, 'desc' | 'asc'] | [];
		path: 'orders' | 'books';
		where?: [string, WhereFilterOp, any] | [];
		observer: {
			next: (snapshot: QuerySnapshot<T>) => void;
			error?: (error: FirestoreError) => void;
			complete?: () => void;
		};
	}) {
		let query = this.addQueryCondition(this.collection<T>(path));

		if (where.length === 3) {
			query = query.where(...where);
		}

		if (orderBy.length === 2) {
			query = query.orderBy(...orderBy);
		}

		return query.onSnapshot(observer);
	}

	public static onSnapshotBook({
		id,
		observer,
	}: {
		id: string;
		observer: {
			next: (snapshot: DocumentSnapshot<Book>) => void;
			error?: (error: FirestoreError) => void;
			complete?: () => void;
		};
	}) {
		const query = this.collection<Book>('books').doc(id);
		return query.onSnapshot(observer);
	}

	public static onSnapshotOrder({
		id,
		observer,
	}: {
		id: string;
		observer: {
			next: (snapshot: DocumentSnapshot<Order>) => void;
			error?: (error: FirestoreError) => void;
			complete?: () => void;
		};
	}) {
		const query = this.collection<Order>('orders').doc(id) as DocumentReference<
			Order
		>;

		return query.onSnapshot(observer);
	}

	public static updateBook({ id, ...data }: PartialBook) {
		return this.collection('books')
			.doc(id)
			.update({ ...data });
	}

	public static updateOrder({ id, ...data }: PartialDeep<Order>) {
		return this.collection<Order>('orders')
			.doc(id)
			.update({ ...data });
	}

	public static getNasToken(): Promise<string> {
		return this.collection<{ synology_sid: string }>('config')
			.doc('token')
			.get()
			.then((snapshot) => {
				if (snapshot.exists) {
					return snapshot.data().synology_sid;
				}
			});
	}
}

export default firebase;
