import { FC, ImgHTMLAttributes } from 'react';
import styled from 'styled-components';

interface LogoWrapperProps extends ImgHTMLAttributes<HTMLImageElement> {
	backgroundShape?: 'circle' | 'square';
}

const LogoWrapper = styled.div<LogoWrapperProps>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: ${(props) => props.color};
	border-radius: ${(props) => {
		return props.backgroundShape === 'circle' ? '100%' : '0%';
	}};
`;

interface LogoProps extends LogoWrapperProps {
	className?: string;
	backgroundColor?: string;
}

//* App Logo
const Logo: FC<LogoProps> = (props) => {
	const { backgroundColor, backgroundShape, ...imgTagAttribute } = props;

	if (backgroundColor) {
		return (
			<LogoWrapper color={backgroundColor} backgroundShape={backgroundShape}>
				<img
					alt="Logo"
					src="/static/logo.svg"
					{...imgTagAttribute}
					style={{ marginRight: 0 }}
				/>
			</LogoWrapper>
		);
	}

	return <img alt="Logo" src="/static/logo.svg" {...imgTagAttribute} />;
};

export default Logo;
