import { AxiosRequestConfig } from 'axios';
import { HttpClient } from './HttpClient';

interface AdminRequestConfig extends AxiosRequestConfig {
	domain?: string;
}

export abstract class AdminApi extends HttpClient {
	constructor(config: AdminRequestConfig) {
		const { domain = '', ...axiosConfig } = config;

		const baseURL = process.env.REACT_APP_ADMIN_SERVER_URL;

		const options = {
			baseURL: domain.length > 0 ? `${baseURL}/${domain}` : baseURL,
			headers: {
				'content-type': 'application/json',
			},
			timeout: 60000,
			...axiosConfig,
		};

		if (process.env.REACT_APP_ENV === 'development') {
			options.headers.mode = 'admin_dev';
		} else {
			options.headers.mode = 'admin_prod';
		}

		super(options);
	}
}
