import './wdyr';
import 'reflect-metadata';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import { Router } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { create } from 'jss';
import React, { FC } from 'react';
import rtl from 'jss-rtl';
import {
	jssPreset,
	Slide,
	StylesProvider,
	ThemeProvider,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import SettingsNotification from 'src/components/SettingsNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import { createTheme } from 'src/theme/createTheme';
import routes, { renderRoutes } from './routes';
import useSettings from 'src/hooks/useSettings';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App: FC = () => {
	const { settings } = useSettings();

	const theme = createTheme({
		direction: settings.direction,
		responsiveFontSizes: settings.responsiveFontSizes,
		theme: settings.theme,
	});

	return (
		<ThemeProvider theme={theme}>
			<StylesProvider jss={jss}>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<SnackbarProvider
						maxSnack={3}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
						TransitionComponent={Slide}
					>
						<Router history={history}>
							<AuthProvider>
								<GlobalStyles />
								<ScrollReset />
								<GoogleAnalytics />
								<SettingsNotification />
								{renderRoutes(routes)}
							</AuthProvider>
						</Router>
					</SnackbarProvider>
				</MuiPickersUtilsProvider>
			</StylesProvider>
		</ThemeProvider>
	);
};

export default App;
