import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
	AppBar,
	Box,
	Button,
	Divider,
	Toolbar,
	Hidden,
	Typography,
	Link,
	makeStyles,
} from '@material-ui/core';
import { APP_VERSION } from 'src/config';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';

interface TopBarProps {
	className?: string;
}

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.default,
	},
	toolbar: {
		height: 64,
	},
	logo: {
		marginRight: theme.spacing(2),
	},
	link: {
		fontWeight: theme.typography.fontWeightMedium,
		'& + &': {
			marginLeft: theme.spacing(2),
		},
	},
	divider: {
		width: 1,
		height: 32,
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
	},
}));

const TopBar: FC<TopBarProps> = ({ className, ...rest }) => {
	const classes = useStyles();
	const { isAuthenticated, logout } = useAuth();

	//* Top Navigation bar component
	return (
		<AppBar className={clsx(classes.root, className)} color="default" {...rest}>
			<Toolbar className={classes.toolbar}>
				<RouterLink to="/">
					<Logo
						className={classes.logo}
						backgroundColor="white"
						backgroundShape="circle"
					/>
				</RouterLink>
				<Divider className={classes.divider} />
				<Hidden mdDown>
					<Typography variant="caption" color="textSecondary">
						Version {APP_VERSION}
					</Typography>
				</Hidden>
				<Box flexGrow={1} />
				<Link
					className={classes.link}
					color="textSecondary"
					component={RouterLink}
					to="/app"
					underline="none"
					variant="body2"
				>
					Go to Dashboard
				</Link>
				<Divider className={classes.divider} />
				<Button
					color="secondary"
					component={RouterLink}
					to={!isAuthenticated ? '/login' : ''}
					variant="contained"
					size="small"
					onClick={isAuthenticated ? logout : null}
				>
					{isAuthenticated ? 'LOGOUT' : 'LOGIN'}
				</Button>
			</Toolbar>
		</AppBar>
	);
};

TopBar.propTypes = {
	className: PropTypes.string,
};

export default TopBar;
