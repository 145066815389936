export { default as firebase, FirebaseService, db } from './firebase';
export * from './credential';

export const AdminApiConfig = {
	baseURL: process.env.REACT_APP_ADMIN_SERVER_URL,
	headers: {
		'content-type': 'application/json',
	},
	timeout: 60000,
};

const studioLink = `${process.env.REACT_APP_API_SERVER_URL}/studio/access?id=`;
const UrlConfig = {
	production: {
		firestore:
			'https://console.firebase.google.com/u/1/project/monvie-international/firestore/data/',
		realtime:
			'https://console.firebase.google.com/u/1/project/monvie-international/database/monvie-international/data/~2F',
		studio: studioLink,
	},
	development: {
		firestore:
			'https://console.firebase.google.com/u/1/project/dev-monvie-international-1be17/firestore/data/',
		realtime:
			'https://console.firebase.google.com/u/1/project/dev-monvie-international-1be17/database/dev-monvie-international-1be17/data/~2F',
		studio: studioLink,
	},
};

export const ENABLE_REDUX_DEV_TOOLS = true;
export const APP_VERSION = '2.0.0';

const synologyDirPath = {
	development: {
		host: 'https://gigafactory.monviestory.com:3006',
		indd: '/products/development/indd',
		indd_reviewed: '/products/development/indd_reviewed',
		background_removal: '/products/development/images/background_removal',
		psd: '/products/development/images/psd',
	},
	production: {
		host: 'https://gigafactory.monviestory.com:3006',
		indd: '/products/production/indd',
		indd_reviewed: '/products/production/indd_reviewed',
		background_removal: '/products/production/images/background_removal',
		psd: '/products/production/images/psd',
	},
};

export const DIRS_PATH =
	synologyDirPath[process.env.REACT_APP_ENV || process.env.NODE_ENV];

export const urlData =
	UrlConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV];
