import { UserRoleType } from '@types';

type UserRole = {
	[role in UserRoleType]: UserRoleType;
};

export const UserRole: UserRole = {
	ADMIN: 'ADMIN',
	DESIGNER: 'DESIGNER',
	DEVELOPER: 'DEVELOPER',
	GUEST: 'GUEST',
	ASSISTANCE: 'ASSISTANCE',
};

export const UserRoles: UserRoleType[] = Object.values(UserRole);

export const UserStatus = {
	ONLINE: 'ONLINE',
	OFFLINE: 'OFFLINE',
};

export const UserAction = {
	DATA_SUMBITTED: 'data_sumbitted',
	BR_REQUESTED: 'br_requested',
	BR_ERROR: 'br_error',
	BR_COMPLETED: 'br_completed',
	INDD_CREATED: 'indd_created',
	MONVIE_REVIEWED: 'monvie_reviewed',
	USER_REIVEWING: 'user_reivewing',
	USER_PENDING: 'user_pending',
};
