import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'src/assets/css/slick.css';
import 'src/__mocks__';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { enableES5 } from 'immer';
import * as serviceWorker from 'src/serviceWorker';
import store from 'src/redux/stores';
import { SettingsProvider } from 'src/contexts/SettingsContext';
import App from 'src/App';
import sourceMap from 'source-map-support';

if (process.env.NODE_ENV === 'development') {
	sourceMap.install();
}

enableES5();

ReactDOM.render(
	<Provider store={store}>
		<SettingsProvider>
			<App />
		</SettingsProvider>
	</Provider>,
	document.getElementById('root'),
);

serviceWorker.register();
