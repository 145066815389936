import { urlData } from 'src/config';

export * from './theme';

export const BOOK_CREATED = 'book_created';
export const BOOK_CANCELED = 'book_canceled';
export const DATA_SUBMITTED = 'data_submitted';
export const BR_REQUESTED = 'br_requested';
export const BR_ERROR = 'br_error';
export const BR_COMPLETED = 'br_completed';
export const INDD_CREATED = 'indd_created';
export const MONVIE_REVIEWED = 'monvie_reviewed';
export const USER_REVIEWING = 'user_reviewing';
export const USER_PENDING = 'user_pending';
export const PRINT_PENDING = 'print_pending';
export const PRINT_REQUESTED = 'print_requested';
export const PRINT_ACCEPTED = 'print_accepted';
export const IN_TRANSIT = 'in_transit';
export const ORDER_DELIVERED = 'order_delivered';

export const STUDIO_URL = urlData.studio;
export const DB_URL = urlData.firestore;
export const REALTIME_DB_URL = urlData.realtime;
export const SYNOLOGY_URL = 'https://monvie.synology.me:5001';
export const UPLOADER_URL = 'https://monvie-uploader.ngrok.io';
