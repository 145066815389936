import { createContext, useEffect, useReducer, FC, ReactNode } from 'react';
import jwtDecode from 'jwt-decode';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'src/utils/axios';
import { AuthApi } from 'src/api';
import { UserApi } from 'src/api/UserApi';
import { User } from '@types';
import { SignupDto } from 'src/dto/signup.dto';

interface AuthState {
	isInitialised: boolean;
	isAuthenticated: boolean;
	user: User | null;
}

interface AuthContextValue extends AuthState {
	method: 'JWT' | 'Auth0' | 'FirebaseAuth';
	login: (email: string, password: string) => Promise<void>;
	logout: () => Promise<void>;
	register: (signupDto: SignupDto) => Promise<void>;
}

interface AuthProviderProps {
	children: ReactNode;
}

type InitialiseAction = {
	type: 'INITIALISE';
	payload: {
		isAuthenticated: boolean;
		user: User | null;
	};
};

type LoginAction = {
	type: 'LOGIN';
	payload: {
		user: User;
	};
};

type LogoutAction = {
	type: 'LOGOUT';
};

type RegisterAction = {
	type: 'REGISTER';
	payload: {
		user: User;
	};
};

type Action = InitialiseAction | LoginAction | LogoutAction | RegisterAction;

type JwtDto = {
	exp: number;
};

const initialAuthState: AuthState = {
	isAuthenticated: false,
	isInitialised: false,
	user: null,
};

const isValidToken = (accessToken: string): boolean => {
	if (!accessToken) {
		return false;
	}

	const decoded = jwtDecode<JwtDto>(accessToken);
	const currentTime = Date.now() / 1000;

	return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null): void => {
	if (accessToken) {
		localStorage.setItem('accessToken', accessToken);
		axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
	} else {
		localStorage.removeItem('accessToken');
		delete axios.defaults.headers.common.Authorization;
	}
};

const reducer = (state: AuthState, action: Action): AuthState => {
	switch (action.type) {
		case 'INITIALISE': {
			const { isAuthenticated, user } = action.payload;

			return {
				...state,
				isAuthenticated,
				isInitialised: true,
				user,
			};
		}
		case 'LOGIN': {
			const { user } = action.payload;

			return {
				...state,
				isAuthenticated: true,
				user,
			};
		}
		case 'LOGOUT': {
			return {
				...state,
				isAuthenticated: false,
				user: null,
			};
		}
		case 'REGISTER': {
			const { user } = action.payload;

			return {
				...state,
				isAuthenticated: true,
				user,
			};
		}
		default: {
			return { ...state };
		}
	}
};

const AuthContext = createContext<AuthContextValue>({
	...initialAuthState,
	method: 'JWT',
	login: () => Promise.resolve(),
	logout: () => Promise.resolve(),
	register: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialAuthState);

	const login = async (email: string, password: string) => {
		const accessToken = await AuthApi.client.login({ email, password });
		const user = await UserApi.getUser({
			headers: { Authorization: accessToken },
		});

		setSession(accessToken);
		dispatch({
			type: 'LOGIN',
			payload: {
				user,
			},
		});
	};

	const logout = async () => {
		await AuthApi.client.logout();

		setSession(null);
		dispatch({ type: 'LOGOUT' });
	};

	const register = async (signupDto: SignupDto) => {
		const { email, password } = signupDto;

		const user = await UserApi.signup(signupDto);
		const accessToken = await AuthApi.client.login({ email, password });

		window.localStorage.setItem('accessToken', accessToken);

		dispatch({
			type: 'REGISTER',
			payload: {
				user,
			},
		});
	};

	useEffect(() => {
		const initialise = async () => {
			try {
				const accessToken = window.localStorage.getItem('accessToken');

				if (accessToken && isValidToken(accessToken)) {
					setSession(accessToken);

					const user = await UserApi.getUser({
						headers: { Authorization: accessToken },
					});

					dispatch({
						type: 'INITIALISE',
						payload: {
							isAuthenticated: true,
							user,
						},
					});
				} else {
					dispatch({
						type: 'INITIALISE',
						payload: {
							isAuthenticated: false,
							user: null,
						},
					});
				}
			} catch (err) {
				console.error(err);
				dispatch({
					type: 'INITIALISE',
					payload: {
						isAuthenticated: false,
						user: null,
					},
				});
			}
		};

		initialise();
	}, []);

	if (!state.isInitialised) {
		return <SplashScreen />;
	}

	return (
		<AuthContext.Provider
			value={{
				...state,
				method: 'JWT',
				login,
				logout,
				register,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
