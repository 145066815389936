import React from 'react';
import { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
	Avatar,
	Button,
	Box,
	Container,
	Grid,
	Typography,
	makeStyles,
} from '@material-ui/core';
import { Theme } from 'src/theme/createTheme';

interface FeaturesProps {
	className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		backgroundColor: theme.palette.background.default,
		paddingTop: 128,
		paddingBottom: 128,
	},
	avatar: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.secondary.contrastText,
	},
}));

const Features: FC<FeaturesProps> = ({ className, ...rest }) => {
	const classes = useStyles();

	return (
		<div className={clsx(classes.root, className)} {...rest}>
			<Container maxWidth="lg">
				<Typography
					component="p"
					variant="overline"
					color="secondary"
					align="center"
				>
					Explore Devias Kit Pro
				</Typography>
				<Typography variant="h1" align="center" color="textPrimary">
					Not just a pretty face
				</Typography>
				<Box mt={8}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={4}>
							<Box display="flex">
								<Avatar className={classes.avatar}>01</Avatar>
								<Box ml={2}>
									<Typography variant="h4" gutterBottom color="textPrimary">
										Complete User Flows
									</Typography>
									<Typography variant="body1" color="textPrimary">
										Not just a set of tools, the package includes the most
										common use cases of user flows like User Management, Second
										Level Layout.
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} md={4}>
							<Box display="flex">
								<Avatar className={classes.avatar}>02</Avatar>
								<Box ml={2}>
									<Typography variant="h4" gutterBottom color="textPrimary">
										Support for Plugins
									</Typography>
									<Typography variant="body1" color="textPrimary">
										The kit provides support for multiple third-party plugins
										right out of the box like Chart.js, Dropzone.js, Kanban
										Plugin and many more.
									</Typography>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={12} md={4}>
							<Box display="flex">
								<Avatar className={classes.avatar}>03</Avatar>
								<Box ml={2}>
									<Typography variant="h4" gutterBottom color="textPrimary">
										Designers, we got you
									</Typography>
									<Typography variant="body1" color="textPrimary" gutterBottom>
										We&apos;ve included the source Sketch &amp; Figma files to
										Plus &amp; Extended licenses so you can get creative! Build
										layouts with confidence.
									</Typography>
									<Button
										variant="outlined"
										component="a"
										href="https://sketch.cloud/s/q4a8e"
										target="_blank"
									>
										Preview Design
									</Button>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Container>
		</div>
	);
};

Features.propTypes = {
	className: PropTypes.string,
};

export default Features;
