import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PartialDeep } from 'type-fest';
import _ from 'lodash';

import { FirebaseService } from 'src/config';
import { AppThunk } from 'src/redux/stores';
import { Order } from '../models';

interface OrderState {
	orders: {
		byId: Record<string, Order>;
		allIds: string[];
	};
}

const initialState: OrderState = {
	orders: {
		byId: {},
		allIds: [],
	},
};

const slice = createSlice({
	name: 'order',
	initialState,
	reducers: {
		addOrders(state: OrderState, action: PayloadAction<{ orders: Order[] }>) {
			const { orders } = action.payload;
			_.forEach(orders, (order) => (state.orders.byId[order.id] = order));

			const ids = _.map(orders, ({ id }) => id);
			state.orders.allIds = _.uniq(state.orders.allIds.concat(ids));
		},
		updateOrder(
			state: OrderState,
			action: PayloadAction<{ order: PartialDeep<Order> }>,
		) {
			const { order } = action.payload;
			const prevOrder = state.orders.byId[order.id];
			state.orders.byId[order.id] = _.merge(prevOrder, order);
		},
	},
});

export const reducer = slice.reducer;

export const addOrders = (orders: Order[]) => (dispatch) => {
	dispatch(slice.actions.addOrders({ orders }));
};

export const fetchOrders = (): AppThunk => async (dispatch) => {
	const orders = await FirebaseService.getOrders();
	dispatch(slice.actions.addOrders({ orders }));
};

export const updateOrder = (order: PartialDeep<Order>): AppThunk => async (
	dispatch,
) => {
	dispatch(slice.actions.updateOrder({ order }));
};
