import { AxiosRequestConfig } from 'axios';
import { User } from '@types';
import { SignupDto } from 'src/dto/signup.dto';
import { AdminApi } from './AdminApi';

export class UserApiManager extends AdminApi {
	private static _userClient: UserApiManager;

	public static get client() {
		if (!this._userClient) {
			this._userClient = new UserApiManager({ domain: 'users' });
		}

		return this._userClient;
	}

	public async getUsers(
		query?: { [key: string]: string },
		option?: AxiosRequestConfig,
	) {
		let url: string = '';

		if (query) {
			url = `?${this.getQueryString(query)}`;
		}

		return this._instance.get<any, User[]>(url, option);
	}

	public async getUser(option?: AxiosRequestConfig) {
		return this._instance.get<any, User>('me', option);
	}

	public async signup(signUpDto: SignupDto) {
		await this.validateInput(SignupDto, signUpDto);

		return this._instance.post<any, User>('', signUpDto);
	}
}

export const UserApi = UserApiManager.client;
